import Axios from "axios";
import mock from "../mocks/manualService";
import { ref } from "vue";
import { uploadData } from "aws-amplify/storage";
import { fetchAuthSession } from "aws-amplify/auth";

const axios = Axios.create({
  baseURL:
    process.env.VUE_APP_API_ENDPOINT || "http://127.0.0.1:3000/api/admin/v1",
});

axios.interceptors.request.use(async (config) => {
  config.headers.Authorization = `Bearer ${(
    await fetchAuthSession()
  ).tokens.idToken.toString()}`;

  return config;
});

if (
  process.env.VUE_APP_USE_MOCK === undefined ||
  JSON.parse(process.env.VUE_APP_USE_MOCK)
) {
  mock.run(axios);
}

function mockPreview(doc_type, doc_no) {
  if (
    process.env.VUE_APP_USE_MOCK === undefined ||
    JSON.parse(process.env.VUE_APP_USE_MOCK)
  ) {
    mock.run(axios, doc_type, doc_no);
  }
}

export default function () {
  const revisions = ref([]);
  const models = ref([]);

  const dateFormat = /^\d{4}-\d{2}-\d{2}$/;
  const dateTimeFormat =
    /(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})\+(\d{2}):(\d{2}$)/;

  // ISO 8601 フォーマットをDateオブジェクトに変換する
  const dateTimeReviver = (key, value) => {
    if (
      typeof value === "string" &&
      (key === "publish_date" || key === "planned_publish_date") &&
      dateFormat.test(value)
    ) {
      return new Date(value);
    } else if (
      typeof value === "string" &&
      key === "approve_date" &&
      dateTimeFormat.test(value)
    ) {
      return new Date(value).toLocaleString();
    } else if (
      typeof value === "string" &&
      key === "created_at" &&
      dateTimeFormat.test(value)
    ) {
      return new Date(value);
    }

    return value;
  };

  // リクエストファイルの名前を取得する
  const getRequestFilename = () => {
    var date = new Date();
    return (
      date.getFullYear() +
      ("0" + (date.getMonth() + 1)).slice(-2) +
      ("0" + date.getDate()).slice(-2) +
      ("0" + date.getHours()).slice(-2) +
      ("0" + date.getMinutes()).slice(-2) +
      ("0" + date.getSeconds()).slice(-2)
    );
  };

  // アップロードフォルダの名前を取得する
  const getUploadFoldername = () => {
    const date = new Date();
    return (
      date.getFullYear() +
      ("0" + (date.getMonth() + 1)).slice(-2) +
      ("0" + date.getDate()).slice(-2) +
      ("0" + date.getHours()).slice(-2) +
      ("0" + date.getMinutes()).slice(-2) +
      ("0" + date.getSeconds()).slice(-2)
    );
  };

  // api : admin-v1-manuals-get
  async function getManuals() {
    return axios.get("/man/manuals", {
      transformResponse: (data) => {
        return JSON.parse(data, dateTimeReviver);
      },
    });
  }

  // api : admin-v1-manuals-detail-get
  async function getManual(doc_type, doc_no) {
    const response = await axios.get(`/man/manuals/${doc_type}/${doc_no}`, {
      transformResponse: (data) => {
        return JSON.parse(data, dateTimeReviver);
      },
    });
    revisions.value = response.data.data.revisions;
  }

  // api : admin-v1-manuals-detail-put
  async function changeManual(doc_type, doc_no, revision, data) {
    return await axios
      .put(`/man/manuals/${doc_type}/${doc_no}/${revision}`, {
        data: data,
        transformResponse: (data) => {
          return JSON.parse(data, dateTimeReviver);
        },
      })
      .then((response) => {
        response.data.data.revisions.forEach((element) => {
          if (element.approve_date != null) {
            element.approve_date = dateTimeReviver(
              "approve_date",
              element.approve_date
            );
          }
        });

        revisions.value = response.data.data.revisions;
        return {
          success: response.data.success,
          message: response.data.meta.messege,
        };
      })
      .catch((error) => {
        return {
          success: error.response.data.success,
          message: error.response.data.meta.message,
        };
      });
  }

  // s3 put event : import workflow
  async function uploadManualWeb(files) {
    const foldername = getUploadFoldername();

    let filenames = [];
    for (let file of files) {
      const filename = `upload/manual/capture/${foldername}/${file.name}`;
      filenames.push(`public/${filename}`);

      try {
        await uploadData({
          key: filename,
          data: file,
          options: {
            accessLevel: "public",
          },
        }).result;
      } catch (error) {
        return {
          success: false,
          meta: {
            message: "アップロードに失敗しました",
          },
        };
      }
    }

    await importManual(filenames, "web").catch(() => {
      return {
        success: false,
        meta: {
          message: "リクエストに失敗しました",
        },
      };
    });

    return {
      success: true,
      meta: {
        message: "アップロードに成功しました",
      },
    };
  }

  // s3 put event : import workflow
  async function uploadManualPDF(files) {
    const foldername = getUploadFoldername();

    let filenames = [];
    for (let file of files) {
      let result = splitFileName(file.name);

      let document = {
        type: result.docType,
        type_name: typeName[result.docType],
        number: result.docNo,
        revision: result.revision,
        locale: locale[result.language],
      };

      // pdfファイルの保存パス
      const pdfFilePath = `upload/manual/capture/${foldername}/${document.locale}/${file.name}`;
      filenames.push(`public/${pdfFilePath}`);

      try {
        await uploadData({
          key: pdfFilePath,
          data: file,
          options: {
            accessLevel: "public",
          },
        }).result;
      } catch (error) {
        return {
          success: false,
          meta: {
            message: "アップロードに失敗しました",
          },
        };
      }

      const documentFilePath = `upload/manual/capture/${foldername}/${document.locale}/document.json`;
      filenames.push(`public/${documentFilePath}`);

      try {
        await uploadData({
          key: documentFilePath,
          data: JSON.stringify(document),
          options: {
            accessLevel: "public",
          },
        }).result;
      } catch (error) {
        return {
          success: false,
          meta: {
            message: "アップロードに失敗しました",
          },
        };
      }
    }

    await importManual(filenames, "pdf").catch(() => {
      return {
        success: false,
        meta: {
          message: "リクエストに失敗しました",
        },
      };
    });

    return {
      success: true,
      meta: {
        message: "アップロードに成功しました",
      },
    };
  }

  // pdfのファイル名から必要な情報を取得する
  const splitFileName = (filename) => {
    const result = filename.match(/^(DM|UM|SI)-(.*)-(.*)-(.*).pdf$/);

    return {
      docType: result[1],
      docNo: result[2],
      revision: result[3],
      language: result[4],
    };
  };

  const typeName = {
    DM: "Dealer's Manual",
    UM: "User's Manual",
    SI: "Service Instruction",
  };

  const locale = {
    ENG: "en-US",
    BUL: "bg-BG",
    CZE: "cs-CZ",
    DAN: "da-DK",
    GER: "de-DE",
    GRE: "el-GR",
    SPA: "es-ES",
    EST: "et-EE",
    FIN: "fi-FI",
    FRE: "fr-FR",
    HRV: "hr-HR",
    HUN: "hu-HU",
    IND: "id-ID",
    ITA: "it-IT",
    JPN: "ja-JP",
    KOR: "ko-KR",
    LIT: "lt-LT",
    LAV: "lv-LV",
    DUT: "nl-NL",
    NOR: "no-NO",
    POL: "pl-PL",
    POR: "pt-BR",
    ROM: "ro-RO",
    RUS: "ru-RU",
    SLK: "sk-SK",
    SLV: "sl-SI",
    SRP: "sr-SP",
    SWE: "sv-SE",
    TUR: "tr-TR",
    THA: "th-TH",
    CHI: "zh-CN",
    CTW: "zh-TW",
  };

  // private function
  async function importManual(filenames, content_type) {
    const filename = getRequestFilename();

    const options = {
      content_type: content_type,
      upload_file: filenames,
    };

    await uploadData({
      key: `workflow/request/manual/capture/${filename}.json`,
      data: JSON.stringify(options),
      options: {
        accessLevel: "public",
      },
    }).result;

    return {
      success: true,
      meta: {
        message: "アップロードに成功しました",
      },
    };
  }

  // s3 put event : publish workflow
  async function publishManual(doc_type, doc_no, revision, publish_type) {
    const filename = getRequestFilename();

    const options = {
      doc_type: doc_type,
      doc_no: doc_no,
      revision: revision,
      publish_type: publish_type,
    };

    await uploadData({
      key: `workflow/request/manual/publish/${filename}.json`,
      data: JSON.stringify(options),
      options: {
        accessLevel: "public",
      },
    }).result;

    return {
      success: true,
      meta: {
        message: "公開をリクエストしました",
      },
    };
  }

  // s3 put event : stop workflow
  async function stopManual(doc_type, doc_no, revision) {
    const filename = getRequestFilename();

    const options = {
      doc_type: doc_type,
      doc_no: doc_no,
      revision: revision,
    };

    await uploadData({
      key: `workflow/request/manual/stop/${filename}.json`,
      data: JSON.stringify(options),
      options: {
        accessLevel: "public",
      },
    }).result;

    return {
      success: true,
      meta: {
        message: "公開停止をリクエストしました",
      },
    };
  }

  // api : admin-v1-manual-model-get
  async function getModels(doc_type, doc_no, revision) {
    const response = await axios.get(
      `/man/manual_model/${doc_type}/${doc_no}/${revision}`
    );
    models.value = response.data.data.manual_model;
  }

  // api : admin-v1-manual-model-post
  async function postManualModel(doc_type, doc_no, revision, data) {
    return await axios
      .post(`/man/manual_model/${doc_type}/${doc_no}/${revision}`, {
        data: data,
      })
      .then((response) => {
        models.value = response.data.data.manual_model;
        return {
          success: response.data.success,
          message: response.data.meta.messege,
        };
      })
      .catch((error) => {
        return {
          success: error.response.data.success,
          message: error.response.data.meta.message,
        };
      });
  }

  // api :admin-v1-manual-preview-get
  const manualpreviews = ref([]);

  async function getManualPreview(doc_type, doc_no) {
    const response = await axios.get(
      `/man/manuals/${doc_type}/${doc_no}/preview`,
      {
        transformResponse: (data) => {
          return JSON.parse(data, dateTimeReviver);
        },
      }
    );
    manualpreviews.value = response.data.data.previews;
  }

  // api :admin-v1-manual-locale-get
  const manualpdftitle = ref([]);
  async function getManualPdfTitle(doc_type, doc_no, revision) {
    await axios
      .get(`/man/manuals/${doc_type}/${doc_no}/${revision}/locale`, {
        transformResponse: (data) => {
          return JSON.parse(data, dateTimeReviver);
        },
      })
      .then((res) => {
        manualpdftitle.value = res.data.data.locales;
      })
      .catch((error) => {
        console.log(error);
      });
  }
  // api :admin-v1-manual-locale-post
  async function postManualPdfTitle(request_data, doc_type, doc_no, revision) {
    return await axios
      .post(
        `/man/manuals/${doc_type}/${doc_no}/${revision}/locale`,
        { data: request_data },
        { headers: { "Content-Type": "application/json" } },
        {
          transformResponse: (data) => {
            return JSON.parse(data, dateTimeReviver);
          },
        }
      )
      .then((res) => {
        manualpdftitle.value = res.data.data.locales;
        return {
          success: res.data.success,
          message: res.data.meta.messege,
        };
      })
      .catch((error) => {
        console.log(error);
        return { success: false, message: String(error) };
      });
  }

  return {
    revisions,
    models,
    manualpreviews,
    manualpdftitle,
    getManuals,
    getManual,
    changeManual,
    uploadManualWeb,
    uploadManualPDF,
    publishManual,
    stopManual,
    getModels,
    postManualModel,
    getManualPreview,
    mockPreview,
    getManualPdfTitle,
    postManualPdfTitle,
  };
}
